@import '../../../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorWhite);
  padding: 50px 0;

  @media (--viewportLargeWithPaddings) {
    padding: 100px 0;
  }
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1730px;
  margin: auto;

  @media (--viewportMedium) {
    gap: 30px;
  }

  @media (--viewportXLarge) {
    gap: 80px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  gap: 0;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid var(--colorBlack);

  @media (--viewportMedium) {
    padding: 45px;
    width: 300px;
    height: 300px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 50px;
    width: 350px;
    height: 350px;
  }

  @media (--viewportMLarge) {
    padding: 120px;
    width: 600px;
    height: 600px;
  }

  @media (--viewportXLarge) {
    padding: 120px;
    width: 636px;
    height: 636px;
  }
}

.block div[class*='BlockDefault_media'] {
  width: 30px;

  & img {
    border-radius: 0px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 45px;
  }

  @media (--viewportMLarge) {
    width: 60px;
  }
}

.block div[class*='BlockDefault_text'] {
  margin-top: 18px;
}

.block h3[class*='Heading_h3'] {
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;

  @media (--viewportLargeWithPaddings) {
    font-size: 25px;
    line-height: 32px;
  }

  @media (--viewportMLarge) {
    font-size: 40px;
    line-height: 48px;
  }
}

.block p {
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;

  @media (--viewportLargeWithPaddings) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (--viewportMLarge) {
    font-size: 25px;
    line-height: 30px;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.header {
  max-width: 1730px;
  margin: auto;
}

.upperHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 70px;
  }
}

.upperHeader h2,
.upperHeader p {
  max-width: unset;
  text-align: left;
}

.upperHeader h2 {
  flex-basis: 40%;
  font-size: 30px;
  font-weight: 300;
  line-height: 42px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 52px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 60px;
    line-height: 72px;
  }
}

.upperHeader p {
  flex-basis: 50%;
  text-wrap: unset;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 25px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 25px;
    line-height: 30px;
  }
}

.ctaWrapper {
  display: flex;
  justify-content: flex-start;

  @media (--viewportLarge) {
    padding-left: 65px;
  }

  @media (--viewportLargeWithPaddings) {
    padding-left: 165px;
  }
}

.ctaBtn {
  width: 172px;
  height: 52px;
  padding: 8px 10px;
  background-color: transparent;
  border: 2px solid var(--colorBlack);
  border-radius: 5px;
  color: var(--colorBlack);
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  text-align: center;
  transition: 0.2s linear;
  margin: 0;
  box-shadow: none;

  &:hover {
    border: 2px solid var(--marketplaceColor);
    color: var(--colorBlack);
  }
}
