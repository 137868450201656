@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  display: flex;
  flex-direction: column;
  max-width: 1730px;
  margin: auto;
  gap: 50px;

  @media (--viewportMedium) {
    gap: 100px;
  }

  @media (--viewportLargeWithPaddings) {
    gap: 150px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportLarge) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.leftContent {

  @media (--viewportLarge) {
    flex-basis: 28%;
  }
}

.leftContent h3 {
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  
  @media (--viewportLarge) {
    font-size: 50px;
    line-height: 62px;
  }

  @media (--viewportXLarge) {
    font-size: 60px;
    line-height: 72px;
  }
}

.leftContent .step {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  
  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 36px;
  }
}

.rightContent {

  @media (--viewportLarge) {
    flex-basis: 45%;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
