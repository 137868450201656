@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  height: 1200px;
  min-height: 80vh;
  max-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchContainer {
  width: 100%;
  margin: auto;
  margin-top: 60px;
}

.root div[class*='SectionContainer_sectionContent'] {
  width: 100%;
}

.root header[class*='SectionBuilder_sectionDetails'] {
  max-width: 1370px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 30px 0;
  
  @media (--viewportMedium) {
    flex-direction: row;
    margin: 0 0 50px 0;
  }
}

.squirrelIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  @media (--viewportMedium) {
    width: 38px;
    height: 38px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 45px;
    height: 43px;
  }

  @media (--viewportMLarge) {
    width: 56px;
    height: 55px;
  }
}

.titleWrapper h1,
.titleWrapper h2,
.dot {
  font-size: 45px;
  line-height: 52px;
  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);
  text-align: center;

  @media (--viewportMedium) {
    font-size: 55px;
    line-height: 65px;
  }

  @media (--viewportLarge) {
    font-size: 70px;
    line-height: 85px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 90px;
    line-height: 110px;
  }
}

.dot {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.root header[class*='SectionBuilder_sectionDetails'] p {
  font-size: 25px;
  line-height: 30px;
  margin-top: 70px;
  font-weight: 400;
  color: var(--marketplaceColor);
  text-align: center;
  margin: 0 0 30px 0;

  @media (--viewportMedium) {
    font-size: 35px;
    line-height: 42px;
    margin-top: 60px;
    margin: 0 0 50px 0;
  }
}

.root header[class*='SectionBuilder_sectionDetails'] a {
  border-radius: 5px;
  border: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  min-width: 215px;
  height: 58px;
  background-color: transparent;
  transition: 0.2s linear;
  text-align: center;

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }
}

.titleIcon {
  width: auto;
  height: 45px;
  margin-left: 10px;

  @media (--viewportMedium) {
    width: auto;
    height: 45px;
  }

  @media (--viewportLarge) {
    width: auto;
    height: 55px;
    margin-left: 20px;
  }

  @media (--viewportLargeWithPaddings) {
    width: auto;
    height: 73px;
  }
}
