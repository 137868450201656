@import '../../../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorWhite);
  padding: 40px 0;

  @media (--viewportLarge) {
    padding: 90px 0;
  }

  @media (--viewportXLarge) {
    padding: 120px 0;
  }
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1730px;
  margin: auto;

  @media (--viewportMedium) {
    gap: 50px;
  }

  @media (--viewportXLarge) {
    gap: 50px;
  }
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0;

  @media (--viewportMedium) {
    padding: 0 25px;
    width: 300px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 40px;
    width: 300px;
  }

  @media (--viewportMLarge) {
    padding: 0 30px;
    width: 420px;
  }

  @media (--viewportXLarge) {
    width: 480px;
  }
}

.block:nth-child(1)::before,
.block:nth-child(2)::before {
  display: none;
  content: '';
  position: absolute;
  top: 40px;
  left: 300px;
  width: 300px;
  height: 1px;
  border-bottom: 1px dashed var(--colorBlack);

  @media (--viewportMLarge) {
    display: block;
  }

  @media (--viewportXLarge) {
    left: 350px;
    width: 320px;
  }
}

.block:nth-child(1)::after,
.block:nth-child(2)::after {
  display: none;
  content: '';
  position: absolute;
  top: 38px;
  right: -188px;
  width: 5px;
  height: 5px;
  background-color: var(--colorBlack);
  border-radius: 50%;

  @media (--viewportMLarge) {
    display: block;
  }

  @media (--viewportXLarge) {
    right: -200px;
  }
}

.block div[class*='BlockDefault_media'] {
  width: 40px;

  & img {
    border-radius: 0px;
    object-fit: contain;
  }

  @media (--viewportLargeWithPaddings) {
    width: 75px;
    height: 75px;
  }
}

.block div[class*='BlockDefault_text'] {
  margin-top: 18px;
}

.block h3[class*='Heading_h3'] {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 15px;

  @media (--viewportLargeWithPaddings) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (--viewportXLarge) {
    font-size: 35px;
    line-height: 42px;
  }
}

.block p {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  padding: 0;

  @media (--viewportMLarge) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (--viewportXLarge) {
    font-size: 22px;
    line-height: 30px;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.header {
  max-width: 1730px;
  margin: auto;
}

.upperHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 70px;
  }
}

.upperHeader h2,
.upperHeader p {
  max-width: unset;
  text-align: left;
}

.upperHeader h2 {
  flex-basis: 40%;
  font-size: 30px;
  font-weight: 300;
  line-height: 42px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 52px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 60px;
    line-height: 72px;
  }
}

.upperHeader p {
  flex-basis: 50%;
  text-wrap: unset;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 25px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 25px;
    line-height: 30px;
  }
}

.ctaWrapper {
  display: flex;
  justify-content: flex-start;

  @media (--viewportLarge) {
    padding-left: 65px;
  }

  @media (--viewportLargeWithPaddings) {
    padding-left: 165px;
  }
}

.ctaBtn {
  width: 172px;
  height: 52px;
  padding: 8px 10px;
  background-color: transparent;
  border: 2px solid var(--colorBlack);
  border-radius: 5px;
  color: var(--colorBlack);
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  text-align: center;
  transition: 0.2s linear;
  margin: 0;
  box-shadow: none;

  &:hover {
    border: 2px solid var(--marketplaceColor);
    color: var(--colorBlack);
  }
}
