@import '../../../../styles/customMediaQueries.css';

.root {

}

.root div[class*="SectionContainer_sectionContent"] {
  padding: 160px 0;
}

.root div[class*="CustomAppearance_backgroundImageWrapper"] {
  filter: brightness(0.3);
}

.root header {
  justify-content: center;
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 308px;
  height: 308px;
  border-radius: 50%;
  border: 2px solid var(--marketplaceColor);
  text-align: center;
  padding: 60px;
}

.block h3 {
  font-size: 18px;
  font-weight: 300;
  line-height: 23px;
  color: var(--marketplaceColor);
  padding: 0;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.ctaBtn {
  min-width: 188px;
  height: 52px;
  background-color: transparent;
  border: 2px solid var(--marketplaceColor);
  border-radius: 5px;
  color: var(--marketplaceColor);
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  margin-top: 0 !important;
  transition: 0.2s linear;

  &:hover {
    color: var(--colorBlack);
  }
}

.sectionTitle {
  font-size: 54px;
  line-height: 1.2em;
  color: var(--marketplaceColor);
  font-weight: 300;
  margin: 0 0 50px 0;
  text-align: center;
}