@import '../../../../styles/customMediaQueries.css';

.root div[class*="SectionContainer_sectionContent"]{
  padding: 0;
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  display: flex;
  flex-direction: column;
  padding: 0 !important;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.blockImage {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportLarge) {
    flex-basis: 58%;
  }
}

.blockImage div[class*="BlockDefault_media"] {
  height: 100%;
}

.blockImage img {
  border-radius: 0;
}

.blockContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  
  @media (--viewportMedium) {
    padding: 80px 80px 80px 100px;
  }

  @media (--viewportLarge) {
    flex-basis: 42%;
  }
}

.blockContent h2 {
  font-weight: 300;
  font-size: 45px;
  line-height: 1.25em;
  margin: 0 0 45px;
}

.blockContent h2 svg {
  display: inline;
}

.blockContent p {
  font-weight: 300;
  font-size: 18px;
  margin: 0 0 45px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.actions {
  display: flex;
  flex-wrap: wrap;
}

.ctaBtn {
  min-width: 172px;
  height: 52;
  padding: 8px 20px;
  background-color: transparent;
  border: 2px solid var(--colorBlack);
  border-radius: 5px;
  margin-right: 17px;
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
  color: var(--colorGrey800);
  text-align: center;
  margin-bottom: 30px;
  transition: 0.2s linear;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColor);
    border: 2px solid var(--marketplaceColor);
  }
}
